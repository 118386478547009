;(function ($, app, window, document, undefined) {
    'use strict';

    var moduleName = 'elementInViewport';
    if(app[moduleName] !== undefined) return;

    var fn = {
        main: function (el) {
            var top = el.offsetTop;
            var left = el.offsetLeft;

            while(el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
                left += el.offsetLeft;
            }

            return (
                top >= window.pageYOffset &&
                left >= window.pageXOffset &&
                top <= (window.pageYOffset + window.innerHeight) &&
                left <= (window.pageXOffset + window.innerWidth)
            );
        }
    };

    //Public method
    app[moduleName] = fn.main;

})(jQuery, window.app = window.app || {}, window, document);

