;(function ($, app, window, document, undefined) {
    'use strict';

    var moduleName = 'clickHandler';
    if(app[moduleName] !== undefined) return;

    var fn = {
        bodyListener: function () {

            $('body').on('click','[data-action]',function(e){
                var method = $(this).data('action'),
                    module = $(this).data('module'),
                    prevent = $(this).data('prevent');

                if(prevent !== false) e.preventDefault();

                if(module === undefined) {
                    throw 'Module not defined (use data-module="")';
                } else if (module in app) {
                    if (method in app[module]) {
                        app[module][method](this);
                    } else {
                        throw 'Method \''+method+'\' not defined in module \''+module+'\'';
                    }
                } else {
                    throw 'Module \''+module+'\' not found';

                }
            });
        }
    };

    fn.bodyListener();

    //Public method
    app[moduleName] = fn.bodyListener;

})(jQuery, window.app = window.app || {}, window, document);

