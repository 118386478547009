;(function ($, app, window, document, undefined) {
    'use strict';

    var moduleName = 'breakpoints';

    var breakpoint = {};
    breakpoint.refreshValue = function () {
        this.value = window.getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content').replace(/\"/g, '');
    };

    $(window).resize(function () {
        breakpoint.refreshValue();
    }).resize();

    //Public methods
    app[moduleName] = {
        breakpoint: breakpoint
    };

})(jQuery, window.app = window.app || {}, window, document);


