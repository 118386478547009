;(function ($, app, window, document, undefined) {
    'use strict';

    app.bem = function(elementName, modifierName){
        var blockName = this.blockName;
        var dot = this.dot;
        var prefix = this.prefix ? this.prefix + '-' : '';
        if(elementName === false && typeof modifierName === 'string') {
            var value = `${dot?'.':''}${prefix}${blockName}_${modifierName}`;
        } else if(elementName === false || elementName === undefined) {
            var value = `${dot?'.':''}${prefix}${blockName}`;
        } else if(typeof modifierName === 'string') {
            var value = `${dot?'.':''}${prefix}${blockName}__${elementName}_${modifierName}`;
        } else {
            var value = `${dot?'.':''}${prefix}${blockName}__${elementName}`;
        }
        return value;
    };


})(jQuery, window.app = window.app || {}, window, document);



